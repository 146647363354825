import { AsyncValidation } from './AsyncValidation/AsyncValidation';

export class Upload {
  fileStatusMap: Record<string, string> = {};

  validateJobDone = false;
  filesNumber = 0;

  constructor(public files: File[], public asyncValidation: AsyncValidation) {
    this.filesNumber = files.length;
  }

  getFileBySha(sha: string): File {
    const idx = this.asyncValidation.shaValidation?.shaFileIndexMap[sha];

    if (idx === undefined) {
      throw new Error('No idx like this');
    }
    return this.files[idx];
  }

  reset(): void {
    this.files = [];
    this.fileStatusMap = {};
    this.filesNumber = 0;

    this.asyncValidation.shaValidation?.resetBatch();

    this.fileStatusMap = {};
    this.validateJobDone = false;
  }
}
