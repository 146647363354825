export class ShaBatchValidation {
  shaBatchSize: number;

  shaFileIndexMap: Record<string, number> = {};

  shaBatch: string[] = [];
  totalBatched = 0;

  filesNumber = 0;

  constructor(public config: { batchSize?: number }) {
    this.shaBatchSize = config.batchSize ?? 100;
  }

  initBatch(validFilesNumber: number): void {
    this.totalBatched = 0;
    this.filesNumber = validFilesNumber;
  }

  addToBatch(sha: string, fileIndex: number): void {
    this.shaBatch.push(sha);
    this.totalBatched += 1;
    this.shaFileIndexMap = { ...this.shaFileIndexMap, [sha]: fileIndex };
  }

  isBatchReady(): boolean {
    return this.shaBatch.length === this.shaBatchSize || this.totalBatched === this.filesNumber;
  }

  resetBatch(): void {
    this.shaBatch = [];
  }

  resetAll(): void {
    this.totalBatched = 0;
    this.shaBatch = [];
    this.shaFileIndexMap = {};
  }
}
