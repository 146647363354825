export class SyncValidation {
  validFiles: number[] = [];

  addValidFile(idx: number): void {
    this.validFiles = [...this.validFiles, idx];
  }

  resetAll(): void {
    this.validFiles = [];
  }

  static validate(files: File[], markFileInvalid: () => void): SyncValidation {
    return files.reduce((acc, file, fileIndex) => {
      const isValid = validateExtensionAndSize(file);

      if (isValid) {
        acc.addValidFile(fileIndex);
      } else {
        markFileInvalid();
      }
      return acc;
    }, new SyncValidation());
  }
}

export const validateExtensionAndSize: (file: File) => boolean = (file) => {
  if (
    (file.name.slice(-4) === '.dat' || file.name.slice(-5) === '.datx') &&
    file.size / 1024 / 1024 < 1
  ) {
    return true;
  } else {
    return false;
  }
};
