import { FileBatch } from './FileBatch';

export class AsyncUpload {
  constructor(public fileBatch: FileBatch, private files: File[]) {}

  getFileBySha(sha: string, shaFileIndexMap: Record<string, number>): File {
    const idx = shaFileIndexMap[sha];

    if (idx === undefined) {
      throw new Error('No idx like this');
    }
    return this.files[idx];
  }

  upload(
    validFilesSha: string[],
    shaFileIndexMap: Record<string, number>,
    postData: (files: File[]) => Promise<void>,
  ): Promise<void> {
    this.fileBatch.initBatch(validFilesSha.length);

    return validFilesSha.reduce(async (acc, sha) => {
      await acc;

      const shaFile = this.getFileBySha(sha, shaFileIndexMap);

      if (!shaFile) {
        console.error('no sha file');
        return Promise.reject();
      }

      shaFile && this.fileBatch?.addToBatch(shaFile);

      if (this.fileBatch.isBatchReady()) {
        const batch = [...this.fileBatch.fileBatch];
        this.fileBatch.resetBatch();
        return postData(batch);
      } else {
        return acc;
      }
    }, Promise.resolve());
  }
}
