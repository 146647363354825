
































































import { AsyncUpload } from '@/core/upload/AsyncUpload/AsyncUpload';
import { FileBatch } from '@/core/upload/AsyncUpload/FileBatch';
import { AsyncValidation } from '@/core/upload/AsyncValidation/AsyncValidation';
import { ShaBatchValidation } from '@/core/upload/AsyncValidation/ShaBatchValidation';
import { SyncValidation } from '@/core/upload/SyncValidation';
import { Upload } from '@/core/upload/Upload';
import { CheckSums, ProcessStatsResponse } from '@/http/HttpResponses';
import { ContentType, RequestParams } from '@/http/myApi';
import { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';

export default Vue.extend({
  data(): {
    upload: Upload | null;
    overlay: boolean;
    uuid: string;
    success: boolean;
  } {
    return {
      upload: null,
      overlay: false,
      uuid: '',
      success: false,
    };
  },
  computed: {
    ...mapState('upload', ['stats']),

    newFilesAdded(): number {
      if (!this.upload) return 0;
      return (
        this.stats.uploadedFiles - this.stats.incorrectFilesAsync - this.stats.duplicatedFilesAsync
      );
    },
    filesMapStatusesLength(): number {
      if (!this.upload) return 0;
      return Object.keys(this.upload.fileStatusMap).length;
    },
    sumOfIncorrectFiles(): number {
      if (!this.upload) return 0;
      return this.stats.incorrectFiles + this.stats.incorrectFilesAsync;
    },
    sumOfDuplicatedFiles(): number {
      if (!this.upload) return 0;
      return this.stats.duplicatedFiles + this.stats.duplicatedFilesAsync;
    },
    uploadedFilesStat(): string {
      if (!this.upload) return '';
      return `${this.stats.finishedFiles}/${this.upload.filesNumber}`;
    },
  },
  methods: {
    ...mapMutations({
      updateStats: 'upload/setUploadStatistics',
      resetUploadStatistics: 'upload/resetUploadStatistics',
      setIsLinksDisabled: 'setIsLinksDisabled',
    }),
    checkStats() {
      return this.$http.process
        .processStatsList(({
          path: `/process/stats/${this.uuid}/`,
        } as unknown) as RequestParams)
        .then((stats) => {
          if (!this.upload) return;
          const {
            processing,
            done,
            error,
            duplcated,
          } = (stats.data as unknown) as ProcessStatsResponse;

          this.updateStats({
            ...this.stats,
            incorrectFilesAsync: error,
            processingFilesAsync: processing,
            duplicatedFilesAsync: duplcated,
          });
        });
    },
    resetAll() {
      this.resetUploadStatistics();
      this.success = false;
    },
    uploadDirectory(files: File[]): void {
      this.resetAll();
      this.uuid = uuidv4();

      if (files.length) {
        this.setIsLinksDisabled(true);

        const shaBatchValidation = new ShaBatchValidation({
          batchSize: 30,
        });
        const asyncValidation = new AsyncValidation(shaBatchValidation);

        this.upload = new Upload(files, asyncValidation);

        this.updateStats({
          ...this.stats,
          initialProcessingFiles: true,
          filesNumber: files.length,
        });

        this.processFiles(files).then(() => {
          setTimeout(() => {
            this.checkStats().then(() => {
              this.success = true;

              this.updateStats({
                ...this.stats,
                initialProcessingFiles: false,
                processingFiles: false,
              });

              this.setIsLinksDisabled(false);

              this.checkStatsInLoop();
            });
          }, 1000);
        });
      }
    },
    async processFiles(files: File[]) {
      const syncValidationResult = this.syncValidation(files);

      this.upload?.asyncValidation.shaValidation?.initBatch(syncValidationResult.validFiles.length);

      const asyncValidationResult = await this.asyncValidation(
        files,
        syncValidationResult.validFiles,
      );

      const validFilesSha = asyncValidationResult.shaValidResult;

      this.updateStats({
        ...this.stats,
        initialProcessingFiles: false,
        processingFiles: true,
      });

      await this.asyncUpload(validFilesSha);
    },
    async checkStatsInLoop() {
      if (this.stats.processingFilesAsync !== 0) {
        setTimeout(async () => {
          this.checkStats().then(() => {
            this.checkStatsInLoop();
          });
        }, 3000);
      }
    },
    async asyncUpload(validFilesSha: string[]) {
      if (!this.upload) return;

      const fileBatch = new FileBatch(validFilesSha.length, { batchSize: 30 });
      const asyncUpload = new AsyncUpload(fileBatch, this.upload.files);

      await asyncUpload.upload(
        validFilesSha,
        this.upload.asyncValidation.shaValidation.shaFileIndexMap,
        this.postData,
      );
    },
    syncValidation(files: File[]) {
      return SyncValidation.validate(files, this.markFileInvalid);
    },
    asyncValidation(files: File[], validFiles: number[]) {
      if (!this.upload) throw new Error('No active upload');

      return this.upload.asyncValidation.validate(
        files,
        validFiles,
        this.readFileAsync,
        this.checkBatchDuplicates,
        this.markFilesInvalid,
        this.markFilesDuplicated,
      );
    },
    readFileAsync(file: File): Promise<ArrayBuffer> {
      return new Promise((resolve, reject) => {
        var reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result as ArrayBuffer);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    markFilesDuplicated(duplicated: number) {
      this.updateStats({
        ...this.stats,
        finishedFiles: this.stats.finishedFiles += duplicated,
        duplicatedFiles: this.stats.duplicatedFiles += duplicated,
      });
    },
    markFilesInvalid(invalid: number) {
      this.updateStats({
        ...this.stats,
        finishedFiles: this.stats.finishedFiles += invalid,
        incorrectFiles: this.stats.incorrectFiles += invalid,
      });
    },
    markFileInvalid() {
      this.updateStats({
        ...this.stats,
        finishedFiles: this.stats.finishedFiles += 1,
        incorrectFiles: this.stats.incorrectFiles += 1,
      });
    },
    postData(files: File[]): Promise<void> {
      const formData = new FormData();

      files.forEach((file, idx) => {
        formData.append('file' + idx, file);
      });

      return this.processUploadCreate(formData).then(() => {
        if (!this.upload) throw new Error('no upload');

        this.updateStats({
          ...this.stats,
          uploadedFiles: this.stats.uploadedFiles += files.length,
          finishedFiles: this.stats.finishedFiles += files.length,
        });
      });
    },
    checkBatchDuplicates(batch: string[]): Promise<Record<string, number>> {
      return this.$http.process.processCheckCreate({ check_sums: batch }).then((resp) => {
        const newResp = (resp as unknown) as AxiosResponse<CheckSums>;
        return newResp.data.check_sums;
      });
    },
    processUploadCreate(data: any, params: RequestParams = {}) {
      return this.$http.request<void, any>({
        path: '/process/upload/',
        method: 'POST',
        body: data,
        secure: true,
        headers: { 'X-UploadSesionId': this.uuid },
        type: ContentType.FormData,
        ...params,
      });
    },
  },
});
