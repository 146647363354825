import { sha256 } from 'js-sha256';
import { CountResult } from './CountResult';
import { ShaBatchValidation } from './ShaBatchValidation';
import { ShaValidResult } from './ShaValidResult';

export class AsyncValidation {
  shaResult = new ShaValidResult();
  countResult = new CountResult();

  constructor(public shaValidation: ShaBatchValidation) {}

  validate(
    files: File[],
    validFiles: number[],
    readFileAsync: (file: File) => Promise<ArrayBuffer>,
    checkBatchDuplicates: (batch: string[]) => Promise<Record<string, number>>,
    markFilesInvalid: (invalid: number) => void,
    markFilesDuplicated: (duplicated: number) => void,
  ): Promise<ShaValidResult> {
    return validFiles.reduce(async (acc, currFileIdx) => {
      await acc;

      return readFileAsync(files[currFileIdx]).then((buffer) => {
        const sha = sha256(buffer);

        this.shaValidation?.addToBatch(sha, currFileIdx);

        if (this.shaValidation?.isBatchReady()) {
          return checkBatchDuplicates(this.shaValidation.shaBatch).then((validatedCheckSums) => {
            this.shaValidation?.resetBatch();

            this.countResult.resetBatch();

            this.countResult.addResults(validatedCheckSums);

            markFilesDuplicated(this.countResult.duplicated);
            markFilesInvalid(this.countResult.invalid);

            this.shaResult.addValidResults(validatedCheckSums);
            return this.shaResult;
          });
        } else {
          return acc;
        }
      });
    }, Promise.resolve(new ShaValidResult()));
  }
}
