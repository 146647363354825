export class ShaValidResult {
  shaValidResult: string[] = [];

  addValidResults(validationResult: Record<string, number>): void {
    const indexes = Object.keys(validationResult).reduce((acc, curr) => {
      if (validationResult[curr] === 0) {
        acc = [...acc, curr];
      }
      return acc;
    }, [] as string[]);
    this.shaValidResult = [...this.shaValidResult, ...indexes];
  }
}
