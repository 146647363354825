import { UploadFileStatus } from '@/models/UploadFileStatusEnum';

export class CountResult {
  valid = 0;
  invalid = 0;
  duplicated = 0;

  addResults(validatedCheckSums: Record<string, UploadFileStatus>): void {
    this.duplicated += this.countResultByStatus(UploadFileStatus.EXISTS, validatedCheckSums);
    this.invalid += this.countResultByStatus(UploadFileStatus.EXISTS_ERROR, validatedCheckSums);
    this.valid += this.countResultByStatus(UploadFileStatus.NOT_EXISTS, validatedCheckSums);
  }

  resetBatch(): void {
    this.valid = 0;
    this.invalid = 0;
    this.duplicated = 0;
  }

  private countResultByStatus(
    status: UploadFileStatus,
    validatedCheckSums: Record<string, UploadFileStatus>,
  ) {
    const result = Object.keys(validatedCheckSums).reduce((acc, curr) => {
      if (validatedCheckSums[curr] === status) {
        acc += 1;
      }
      return acc;
    }, 0);
    return result;
  }
}
