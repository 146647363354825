export class FileBatch {
  fileBatchSize: number;

  fileBatch: File[] = [];
  totalBatched = 0;

  constructor(public filesNumber: number, public config: { batchSize?: number }) {
    this.fileBatchSize = config.batchSize ?? 15;
  }

  initBatch(validFilesNumber: number): void {
    this.totalBatched = 0;
    this.filesNumber = validFilesNumber;
  }

  addToBatch(file: File): void {
    this.fileBatch.push(file);
    this.totalBatched += 1;
  }

  isBatchReady(): boolean {
    return this.fileBatch.length === this.fileBatchSize || this.totalBatched === this.filesNumber;
  }

  resetBatch(): void {
    this.fileBatch = [];
  }

  resetAll(): void {
    this.totalBatched = 0;
    this.fileBatch = [];
  }
}
